
import { defineComponent } from 'vue';
import AnthropometryProfile from './AnthropometryProfile.vue';
import AnthropometryPrev from './AnthropometryPrev.vue';

export default defineComponent({
  name: 'AnthropometryProfileAndPrev',
  components: {
    AnthropometryProfile,
    AnthropometryPrev,
  },
  props: {
    thresholds: {
      type: Function,
    },
    viewBy: {
      type: String,
      default: 'GAM',
    },
  },
});
