
import { defineComponent, PropType } from 'vue';
import Plotly, { Data } from 'plotly.js-finance-dist-min';
import dayjs from 'dayjs';
import { surveysSubProps } from '@/utils/request';
import { generateHoverTemplates } from '@/utils/functions';
import { CaretDownFilled } from '@ant-design/icons-vue';
// eslint-disable-next-line
import { Dropdown, Button, Menu, Empty } from 'ant-design-vue';

const { Item } = Menu;

export default defineComponent({
  name: 'Underweight',
  props: {
    data: {
      type: Array as PropType<surveysSubProps[]>,
      default: [] as surveysSubProps[],
    },
    isMonth: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Button,
    CaretDownFilled,
    Dropdown,
    Menu,
    Item,
    Empty,
  },
  data() {
    return {
      sex: 'All',
      levels: ['Global'],
      dataExists: true,
      selected: 'Underweight',
    };
  },
  mounted() {
    this.underWeight();
  },
  computed: {
    getLoading() {
      return this.$store.getters['surveysFilter/getLoading'];
    },
  },
  methods: {
    onSelect({ key }:{ key: string }) {
      let title = 'Underweight';
      if (key === 'Moderate') {
        title = 'Moderate Underweight';
      } else if (key === 'Severe') {
        title = 'Severe Underweight';
      }
      this.selected = title;
    },
    applyFilters() {
      const y = this.data.map((survey:surveysSubProps) => {
        let x;
        let propLow;
        let propUp;
        if (this.levels[0] === 'Global') {
          if (this.sex === 'All') {
            x = survey.keystats?.underweight?.global?.all?.prop;
            propLow = survey.keystats?.underweight?.global?.all?.prop_low;
            propUp = survey.keystats?.underweight?.global?.all?.prop_upp;
          } else if (this.sex === 'Female') {
            x = survey.keystats?.underweight?.global?.f?.prop;
            propLow = survey.keystats?.underweight?.global?.f?.prop_low;
            propUp = survey.keystats?.underweight?.global?.f?.prop_upp;
          } else {
            x = survey.keystats?.underweight?.global?.m?.prop;
            propLow = survey.keystats?.underweight?.global?.m?.prop_low;
            propUp = survey.keystats?.underweight?.global?.m?.prop_upp;
          }
        } else if (this.levels[0] === 'Moderate') {
          if (this.sex === 'All') {
            x = survey.keystats?.underweight?.moderate?.all?.prop;
            propLow = survey.keystats?.underweight?.moderate?.all?.prop_low;
            propUp = survey.keystats?.underweight?.moderate?.all?.prop_upp;
          } else if (this.sex === 'Female') {
            x = survey.keystats?.underweight?.moderate?.f?.prop;
            propLow = survey.keystats?.underweight?.moderate?.f?.prop_low;
            propUp = survey.keystats?.underweight?.moderate?.f?.prop_upp;
          } else {
            x = survey.keystats?.underweight?.moderate?.m?.prop;
            propLow = survey.keystats?.underweight?.moderate?.m?.prop_low;
            propUp = survey.keystats?.underweight?.moderate?.m?.prop_upp;
          }
        } else if (this.levels[0] === 'Severe') {
          if (this.sex === 'All') {
            x = survey.keystats?.underweight?.severe?.all?.prop;
            propLow = survey.keystats?.underweight?.severe?.all?.prop_low;
            propUp = survey.keystats?.underweight?.severe?.all?.prop_upp;
          } else if (this.sex === 'Female') {
            x = survey.keystats?.underweight?.severe?.f?.prop;
            propLow = survey.keystats?.underweight?.severe?.f?.prop_low;
            propUp = survey.keystats?.underweight?.severe?.f?.prop_upp;
          } else {
            x = survey.keystats?.underweight?.severe?.m?.prop;
            propLow = survey.keystats?.underweight?.severe?.m?.prop_low;
            propUp = survey.keystats?.underweight?.severe?.m?.prop_upp;
          }
        }
        if (!x) x = 0;
        if (!propLow) propLow = 0;
        if (!propUp) propUp = 0;
        x *= 100;
        propUp *= 100;
        propLow *= 100;
        return [x, survey, propLow, propUp];
      });
      return y;
    },
    underWeight() {
      const { width, height } = (this.$refs.underChart as Element).getBoundingClientRect();
      const t = this.renderUnderWeight();
      let tick0;
      let rest;
      let dtick;
      if (!this.isMonth) {
        // @ts-expect-error cant find x in traces
        [tick0, rest] = t[0].x;
        dtick = 1;
      }
      const layout = {
        width,
        height: 450,
        xaxis: {
          dtick,
          tick0,
          showgrid: false,
          linecolor: '#e2e2e2',
          tickfont: {
            color: 'rgba(54, 53, 52, 0.74)',
            family: 'DINNextLTPro-Regular, Arial',
            size: 12,
          },
        },
        yaxis: {
          rangemode: 'tozero',
          range: [0, 101],
          tickfont: {
            color: 'rgba(54, 53, 52, 0.74)',
            family: 'DINNextLTPro-Regular, Arial',
          },
        },
        legend: {
          xanchor: 'center',
          yanchor: 'top',
          y: -0.15,
          x: 0.5,
        },
        margin: {
          t: 20,
          l: 25,
          r: 20,
          b: 80,
        },
      };
      // @ts-expect-error enum error expected
      Plotly.newPlot('Underweight', t, layout);
    },
    renderUnderWeight():Data[] {
      let data = this.applyFilters();
      if (this.getLoading === 'done') this.dataExists = data.length > 0;
      data = data.sort((a, b) => {
        let sorted;
        const surveyA = a[1] as surveysSubProps;
        const surveyB = b[1] as surveysSubProps;
        if (this.isMonth) {
          if (((surveyA.surveyYear * 100) + surveyA.surveyMonth) > ((surveyB.surveyYear * 100) + surveyB.surveyMonth)) {
            sorted = 1;
          } else {
            sorted = -1;
          }
        } else if ((a[1] as surveysSubProps).surveyYear > (b[1] as surveysSubProps).surveyYear) {
          sorted = 1;
        } else {
          sorted = -1;
        }
        return sorted;
      });
      const x: (number|string)[] = data.map((i) => {
        const survey = i[1] as surveysSubProps;
        let filterDate: string|number = survey.surveyYear;
        if (this.isMonth) {
          filterDate = dayjs().set('year', survey.surveyYear).set('month', survey.surveyMonth - 1).format('MMM/YYYY');
        }
        return filterDate;
      });
      const y: number[] = data.map((i) => i[0] as number);
      const text = data.map((i) => {
        const l = i[1] as surveysSubProps;
        let loc = this.parseLocationName(l);
        if (typeof (loc) !== 'string') {
          loc = 'N/A';
        }
        return loc;
      });
      const customdata = data.map((i) => {
        const propLow = i[2] as number;
        const propUp = i[3] as number;
        return `${propLow.toFixed(1)} - ${propUp.toFixed(1)}`;
      });
      const hovertemplate = generateHoverTemplates(x, y, text, customdata, '#1894e6');
      const traces:Data = {
        x,
        y,
        text,
        customdata,
        textinfo: 'none',
        hovertemplate,
        hoverlabel: {
          bgcolor: '#363534',
          bordercolor: '#363534',
          font: {
            color: '#FFFFFF',
            family: 'DINNextLTPro',
            size: 12.5,
          },
          align: 'left',
        },
        mode: 'markers',
        type: 'scatter',
        marker: {
          color: '#1894e6',
          size: 8,
          opacity: 0.5,
        },
        textfont: {
          family: 'DINNextLTPro-Regular',
        },
        connectgaps: true,
        showlegend: false,
      };

      return [traces];
    },
    parseLocationName(marker:surveysSubProps) {
      return marker.geographicAreaName;
    },
  },
  watch: {
    data(newProps, oldProps) {
      if (newProps !== oldProps) {
        this.underWeight();
      }
    },
    sex(newProps, oldProps) {
      if (newProps !== oldProps) {
        this.underWeight();
      }
    },
    levels(newProps, oldProps) {
      if (newProps !== oldProps) {
        this.underWeight();
      }
    },
  },
});
