
import { defineComponent, PropType } from 'vue';
import { surveysSubProps } from '@/utils/request';
import Plotly, { Data } from 'plotly.js-finance-dist-min';

export default defineComponent({
  name: 'AnthropometryProfile',
  props: {
    viewBy: {
      type: String,
    },
    thresholds: {
      type: Function,
    },
  },
  mounted() {
    this.graph();
  },
  computed: {
    data() {
      const surveys = this.$store.getters['surveysFilter/getData'];
      const arr = new Array(5).fill(0);
      surveys.forEach((survey: surveysSubProps) => {
        let m;
        if (this.viewBy === 'GAM') m = survey?.keystats?.gam?.whz?.all?.prop;
        if (this.viewBy === 'Stunting') m = survey?.keystats?.stunting?.global?.all?.prop;
        if (this.viewBy === 'Overweight') m = survey?.keystats?.overweight?.global?.all?.prop;
        if (typeof (m) !== 'undefined' && survey?.surveyYear && survey?.surveyMonth && this.$props.thresholds) {
          m *= 100;
          arr[this.$props.thresholds(m)] += 1;
        }
      });
      return arr;
    },
  },
  methods: {
    graph() {
      const count = this.data.reduce((prev, curr) => (prev + curr), 0);
      const text = `${this?.data ? count : 0}`;
      const layout = {
        height: 300,
        margin: {
          t: 25,
          l: 25,
        },
        legend: {
          font: {
            family: 'DINNextLTPro-Medium',
            size: 14,
            color: '#363534',
          },
          y: 0.13,
        },
        annotations: [
          {
            text,
            x: 0.5,
            y: 0.55,
            showarrow: false,
            font: {
              family: 'DINNextLTPro-Bold',
              size: 37,
              color: '#363534',
            },
          },
          {
            text: 'Total Surveys',
            x: 0.5,
            y: 0.42,
            showarrow: false,
            font: {
              family: 'DINNextLTPro-Bold',
              size: 14,
              color: '#363534',
            },
          },
        ],
      };
      const data = this.renderProf();
      // @ts-expect-errror enum error
      Plotly.newPlot('anthro-prof', data, layout);
    },
    renderProf(): Data[] {
      let labels;
      if (this.$props.viewBy === 'GAM') labels = ['Very low: <2.5%', 'Low: 2.5%-4.9%', 'Medium: 5.0%-9.9%', 'High: 10.0%-14.9%', 'Very High: ≥15.0%'];
      if (this.$props.viewBy === 'Stunting') labels = ['Very low: <2.5%', 'Low: 2.5%-9.9%', 'Medium: 10.0%-19.9%', 'High: 20.0%-29.9%', 'Very High: ≥30%'];
      if (this.$props.viewBy === 'Overweight') labels = ['Very low: <2.5%', 'Low: 2.5%-4.9%', 'Medium: 5.0%-9.9%', 'High: 10.0%-14.9%', 'Very High: ≥15.0%'];
      const traces:Data = {
        values: this.data,
        labels,
        domain: { column: 0 },
        sort: false,
        direction: 'clockwise',
        textinfo: 'none',
        marker: {
          colors: ['#2caf80', '#8cd07f', '#f3c912', '#e98300', '#e41919'],
        },
        rotation: -60,
        hovertemplate: '<b style="color: %{color}">&#10687;</b>  <span><b>%{text}</b>  %{percent}, n=%{value}</span><extra></extra>',
        text: ['Very low', 'Low', 'Medium', 'High', 'Very High'],
        hoverinfo: 'label+percent+name',
        hoverlabel: {
          bgcolor: '#363534',
          bordercolor: '#363534',
          font: {
            color: '#FFFFFF',
            family: 'DINNextLTPro',
            size: 12.5,
          },
        },
        hole: 0.6,
        type: 'pie',
      };
      return [traces];
    },
  },
  watch: {
    viewBy(newProps, oldProps) {
      if (newProps !== oldProps) {
        this.graph();
      }
    },
    data() {
      this.graph();
    },
  },
});
