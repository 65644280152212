
import { defineComponent } from 'vue';
import { surveysSubProps } from '@/utils/request';
import MapResults from '@/components/Surveys/MapResults/index.vue';
import darkGreenMarker from '@/assets/svg/dark-green-marker.svg';
import lightGreenMarker from '@/assets/svg/light-green-marker.svg';
import orangeMarker from '@/assets/svg/orange-marker.svg';
import yellowMarker from '@/assets/svg/yellow-marker.svg';
import lightRedMarker from '@/assets/svg/red-marker.svg';
import lightGreenPlus from '@/assets/svg/lightGreenPlus.svg';
import darkGreenPlus from '@/assets/svg/darkGreenPlus.svg';
import lightRedPlus from '@/assets/svg/lightRedPlus.svg';
import yellowPlus from '@/assets/svg/yellowPlus.svg';
import orangePlus from '@/assets/svg/orangePlus.svg';
// marker triangle
import triDarkGreenMarker from '@/assets/svg/triangleMarker/darkGreenTri.svg';
import triLightGreenMarker from '@/assets/svg/triangleMarker/lightGreenTri.svg';
import triOrangeMarker from '@/assets/svg/triangleMarker/orangeTri.svg';
import triYellowMarker from '@/assets/svg/triangleMarker/yellowTri.svg';
import triLightRedMarker from '@/assets/svg/triangleMarker/redTri.svg';
import triLightGreenPlus from '@/assets/svg/triangleMarker/lightGreenTriPlus.svg';
import triDarkGreenPlus from '@/assets/svg/triangleMarker/darkGreenTriPlus.svg';
import triLightRedPlus from '@/assets/svg/triangleMarker/redTriPlus.svg';
import triYellowPlus from '@/assets/svg/triangleMarker/yellowTriPlus.svg';
import triOrangePlus from '@/assets/svg/triangleMarker/orangeTriPlus.svg';

export default defineComponent({
  name: 'AnthropometryMap',
  components: {
    MapResults,
  },
  props: {
    thresholds: {
      type: Function,
    },
    viewBy: {
      type: String,
    },
  },
  computed: {
    isFiltered() {
      return !!this.getRegion;
    },
    getRegion() {
      const loc = this.$store.getters['surveysFilter/getLocation'];
      return loc;
    },
    getMapData() {
      const mapData:surveysSubProps[] = [];
      const data = this.$store.getters['surveysFilter/getData'];
      if (data) {
        data.forEach((s: surveysSubProps) => {
          let markerInfo;
          if (this.$props.viewBy === 'GAM') markerInfo = s.keystats?.gam?.whz?.all?.prop;
          if (this.$props.viewBy === 'Stunting') markerInfo = s.keystats?.stunting?.global?.all?.prop;
          if (this.$props.viewBy === 'Overweight') markerInfo = s.keystats?.overweight?.global?.all?.prop;
          let inMapDataIndex = mapData.findIndex((m) => ((m.latitude && m.latitude.toFixed(2) === (s.latitude && s.latitude.toFixed(2))) && (m.longitude && m.longitude.toFixed(2) === (s.latitude && s.longitude.toFixed(2)))));
          if (typeof (markerInfo) === 'number' && s.surveyYear && s.surveyMonth && s?.longitude && s?.latitude) {
            if (inMapDataIndex === -1) {
              mapData.push({ ...s, previousSurveys: undefined });
              inMapDataIndex = mapData.length - 1;
            }
            const survey = mapData[inMapDataIndex];
            if (typeof survey.previousSurveys === 'undefined') {
              survey.previousSurveys = 0;
            } else {
              survey.previousSurveys += 1;
            }
            if (s.surveyMonth >= survey.surveyMonth && s.surveyYear >= survey.surveyYear) {
              mapData[inMapDataIndex] = { ...s, previousSurveys: survey.previousSurveys };
            }
          }
        });
      }
      return mapData;
    },
  },
  methods: {
    filterColor(markerData: surveysSubProps) {
      const { previousSurveys, surveyType } = markerData;
      let markerInfo;
      if (this.$props.viewBy === 'GAM') markerInfo = markerData.keystats?.gam?.whz?.all?.prop;
      if (this.$props.viewBy === 'Stunting') markerInfo = markerData.keystats?.stunting?.global?.all?.prop;
      if (this.$props.viewBy === 'Overweight') markerInfo = markerData.keystats?.overweight?.global?.all?.prop;
      let marker = darkGreenMarker;
      if (surveyType !== 'NNS' && typeof (markerInfo) === 'number' && this.$props.thresholds) {
        if (previousSurveys && previousSurveys > 0) {
          const markerSvgs = [darkGreenPlus, lightGreenPlus, yellowPlus, orangePlus, lightRedPlus];
          marker = markerSvgs[this.$props.thresholds(markerInfo * 100)];
        } else {
          const markerSvgs = [darkGreenMarker, lightGreenMarker, yellowMarker, orangeMarker, lightRedMarker];
          marker = markerSvgs[this.$props.thresholds(markerInfo * 100)];
        }
      } else if (surveyType === 'NNS' && typeof (markerInfo) === 'number' && this.$props.thresholds) {
        if (previousSurveys && previousSurveys > 0) {
          const markerSvgs = [triDarkGreenPlus, triLightGreenPlus, triOrangePlus, triYellowPlus, triLightRedPlus];
          marker = markerSvgs[this.$props.thresholds(markerInfo * 100)];
        } else {
          const markerSvgs = [triDarkGreenMarker, triLightGreenMarker, triOrangeMarker, triYellowMarker, triLightRedMarker];
          marker = markerSvgs[this.$props.thresholds(markerInfo * 100)];
        }
      }
      return marker;
    },
    async onPopoverClick(m: surveysSubProps) {
      let loc = m.geographicCountry;
      let scope = 'National';
      if (m.administrative_area_level_1) {
        loc = m.administrative_area_level_1;
        scope = 'Sub-National';
      } else if (m.administrative_area_level_2) {
        loc = m.administrative_area_level_2;
        scope = 'Sub National';
      } else if (m.administrative_area_level_3) {
        loc = m.administrative_area_level_3;
        scope = 'Sub National';
      }
      await this.$store.dispatch('surveysFilter/updateGeographicName', scope);
      await this.$store.dispatch('surveysFilter/updateLocation', loc);
      await this.$store.dispatch('surveysFilter/updateData').then(() => {
        (this.$refs.anthroMapRef as typeof MapResults).zoomLevel = 7;
        (this.$refs.anthroMapRef as typeof MapResults).position.latitude = m.latitude;
        (this.$refs.anthroMapRef as typeof MapResults).position.longitude = m.longitude;
      });
    },
  },
});
