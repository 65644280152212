
import { defineComponent } from 'vue';
import { surveysSubProps } from '@/utils/request';
import dayjs from 'dayjs';
import GAM from './GAM.vue';
import Stunting from './Stunting.vue';
import Underweight from './Underweight.vue';
import Overweight from './Overweight.vue';

export default defineComponent({
  name: 'AnthropometryResults',
  components: {
    GAM,
    Stunting,
    Underweight,
    Overweight,
  },
  computed: {
    getData() {
      const d: surveysSubProps<'anthro'>[] = this.$store.getters['surveysFilter/getData'];
      return d.filter((s) => (s?.surveyYear && s?.surveyMonth && s?.keystats));
    },
    getDateDiff() {
      const getDate = this.$store.getters['surveysFilter/getDate'];
      let dateDiff = false;
      if (!getDate[0] && getDate[1]) {
        dateDiff = true;
      } else if (getDate[0] || getDate[1]) {
        const fromDate = dayjs(getDate[0]);
        const toDate = dayjs(getDate[1]);
        dateDiff = Math.abs(((fromDate.year() * 100) + fromDate.month()) - ((toDate.year() * 100) + toDate.month())) <= 12;
      }
      return dateDiff;
    },
  },
});
