
import { defineComponent } from 'vue';
import Anthropometry from '../components/Surveys/Anthropometry/index.vue';

export default defineComponent({
  name: 'AnthropometrySurveys',
  components: {
    Anthropometry,
  },
  async mounted() {
    await this.$store.dispatch('surveysFilter/updateLocationOptions');
    await this.$store.dispatch('surveysFilter/updateOrganizationOptions');
  },
});

