
import { defineComponent, PropType } from 'vue';
import dayjs from 'dayjs';
import Plotly, { Data, Layout } from 'plotly.js-finance-dist-min';
import { surveysSubProps } from '@/utils/request';
import { generateHoverTemplates } from '@/utils/functions';
import { CaretDownFilled } from '@ant-design/icons-vue';
// eslint-disable-next-line
import { Select, Dropdown, Button, Menu, Empty } from 'ant-design-vue';

const { Item } = Menu;
const { Option } = Select;

export default defineComponent({
  name: 'GAM',
  components: {
    Dropdown,
    Button,
    CaretDownFilled,
    Select,
    Option,
    Menu,
    Item,
    Empty,
  },
  data() {
    return {
      dataExists: true,
      sex: 'All',
      type: ['GAM'],
      filter: 'Weight-for-Height',
      filterOpen: false,
    };
  },
  props: {
    data: {
      type: Array as PropType<surveysSubProps[]>,
      default: [] as surveysSubProps[],
    },
    isMonth: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.gam();
  },
  computed: {
    getLoading() {
      return this.$store.getters['surveysFilter/getLoading'];
    },
  },
  methods: {
    applyFilters() {
      const y:Array<[number, surveysSubProps<'anthro'>, number, number]> = [];
      this.data.forEach((survey:surveysSubProps) => {
        let x;
        let propLow;
        let propUp;
        if (this.type[0] === 'GAM') {
          if (this.filter === 'Weight-for-Height') {
            if (this.sex === 'All') {
              x = survey.keystats?.gam?.whz?.all?.prop;
              propLow = survey.keystats?.gam?.whz?.all?.prop_low;
              propUp = survey.keystats?.gam?.whz?.all?.prop_upp;
            } else if (this.sex === 'Female') {
              x = survey.keystats?.gam?.whz?.f?.prop;
              propLow = survey.keystats?.gam?.whz?.f?.prop_low;
              propUp = survey.keystats?.gam?.whz?.f?.prop_upp;
            } else {
              x = survey.keystats?.gam?.whz?.m?.prop;
              propLow = survey.keystats?.gam?.whz?.m?.prop_low;
              propUp = survey.keystats?.gam?.whz?.m?.prop_upp;
            }
          } else if (this.filter === 'MUAC') {
            if (this.sex === 'All') {
              x = survey.keystats?.gam?.muac?.all?.prop;
              propLow = survey.keystats?.gam?.muac?.all?.prop_low;
              propUp = survey.keystats?.gam?.muac?.all?.prop_upp;
            } else if (this.sex === 'Female') {
              x = survey.keystats?.gam?.muac?.f?.prop;
              propLow = survey.keystats?.gam?.muac?.f?.prop_low;
              propUp = survey.keystats?.gam?.muac?.f?.prop_upp;
            } else {
              x = survey.keystats?.gam?.muac?.m?.prop;
              propLow = survey.keystats?.gam?.muac?.m?.prop_low;
              propUp = survey.keystats?.gam?.muac?.m?.prop_upp;
            }
          }
        } else if (this.type[0] === 'SAM') {
          if (this.filter === 'Weight-for-Height') {
            if (this.sex === 'All') {
              x = survey.keystats?.sam?.whz?.all?.prop;
              propLow = survey.keystats?.sam?.whz?.all?.prop_low;
              propUp = survey.keystats?.sam?.whz?.all?.prop_upp;
            } else if (this.sex === 'Female') {
              x = survey.keystats?.sam?.whz?.f?.prop;
              propLow = survey.keystats?.sam?.whz?.f?.prop_low;
              propUp = survey.keystats?.sam?.whz?.f?.prop_upp;
            } else {
              x = survey.keystats?.sam?.whz?.m?.prop;
              propLow = survey.keystats?.sam?.whz?.m?.prop_low;
              propUp = survey.keystats?.sam?.whz?.m?.prop_upp;
            }
          } else if (this.filter === 'MUAC') {
            if (this.sex === 'All') {
              x = survey.keystats?.sam?.muac?.all?.prop;
              propLow = survey.keystats?.sam?.muac?.all?.prop_low;
              propUp = survey.keystats?.sam?.muac?.all?.prop_upp;
            } else if (this.sex === 'Female') {
              x = survey.keystats?.sam?.muac?.f?.prop;
              propLow = survey.keystats?.sam?.muac?.f?.prop_low;
              propUp = survey.keystats?.sam?.muac?.f?.prop_upp;
            } else {
              x = survey.keystats?.sam?.muac?.m?.prop;
              propLow = survey.keystats?.sam?.muac?.m?.prop_low;
              propUp = survey.keystats?.sam?.muac?.m?.prop_upp;
            }
          }
        } else if (this.type[0] === 'MAM') {
          if (this.filter === 'Weight-for-Height') {
            if (this.sex === 'All') {
              x = survey.keystats?.mam?.whz?.all?.prop;
              propLow = survey.keystats?.mam?.whz?.all?.prop_low;
              propUp = survey.keystats?.mam?.whz?.all?.prop_upp;
            } else if (this.sex === 'Female') {
              x = survey.keystats?.mam?.whz?.f?.prop;
              propLow = survey.keystats?.mam?.whz?.f?.prop_low;
              propUp = survey.keystats?.mam?.whz?.f?.prop_upp;
            } else {
              x = survey.keystats?.mam?.whz?.m?.prop;
              propLow = survey.keystats?.mam?.whz?.m?.prop_low;
              propUp = survey.keystats?.mam?.whz?.m?.prop_upp;
            }
          } else if (this.filter === 'MUAC') {
            if (this.sex === 'All') {
              x = survey.keystats?.mam?.muac?.all?.prop;
              propLow = survey.keystats?.mam?.muac?.all?.prop_low;
              propUp = survey.keystats?.mam?.muac?.all?.prop_upp;
            } else if (this.sex === 'Female') {
              x = survey.keystats?.mam?.muac?.f?.prop;
              propLow = survey.keystats?.mam?.muac?.f?.prop_low;
              propUp = survey.keystats?.mam?.muac?.f?.prop_upp;
            } else {
              x = survey.keystats?.mam?.muac?.m?.prop;
              propLow = survey.keystats?.mam?.muac?.m?.prop_low;
              propUp = survey.keystats?.mam?.muac?.m?.prop_upp;
            }
          }
        }
        if (!x) x = 0;
        if (!propLow) propLow = 0;
        if (!propUp) propUp = 0;
        x *= 100;
        propUp *= 100;
        propLow *= 100;
        y.push([x, survey, propLow, propUp]);
      });
      return y;
    },
    gam() {
      const { width, height } = (this.$refs.gamChart as Element).getBoundingClientRect();
      const t = this.renderGAM();
      let tick0;
      let rest;
      let dtick;
      if (!this.isMonth) {
        // @ts-expect-error cant find x in traces
        [tick0, rest] = t[0].x;
        dtick = 1;
      }
      let shapes: Layout['shapes'] = [];
      if (this.type[0] === 'GAM') {
        shapes = [
          {
            name: 'Critical',
            type: 'line',
            xref: 'paper',
            x0: 0,
            y0: 15.0,
            x1: 1,
            y1: 15.0,
            line: {
              color: 'rgb(255, 0, 0)',
              width: 2,
              dash: 'longdash',
            },
          }];
      }
      const layout = {
        width,
        height,
        xaxis: {
          tick0,
          dtick,
          showgrid: false,
          linecolor: '#e2e2e2',
          tickfont: {
            color: 'rgba(54, 53, 52, 0.74)',
            family: 'DINNextLTPro-Regular, Arial',
            size: 12,
          },
        },
        yaxis: {
          rangemode: 'tozero',
          range: [0, 101],
          tickfont: {
            color: 'rgba(54, 53, 52, 0.74)',
            family: 'DINNextLTPro-Regular, Arial',
          },
        },
        legend: {
          xanchor: 'center',
          yanchor: 'top',
          y: -0.1,
          x: 0.5,
          font: {
            family: 'DINNextLTPro-Medium',
            size: 12,
            color: '#363534',
          },
        },
        margin: {
          t: 20,
          l: 25,
          r: 20,
        },
        shapes,
      };
      // @ts-expect-error enum error expected
      Plotly.newPlot('GAM', t, layout);
    },
    renderGAM():Data[] {
      let data = this.applyFilters();
      if (this.getLoading === 'done') this.dataExists = data.length > 0;
      data = data.sort((a, b) => {
        let sorted;
        const surveyA = a[1] as surveysSubProps;
        const surveyB = b[1] as surveysSubProps;
        if (this.isMonth) {
          if (((surveyA.surveyYear * 100) + surveyA.surveyMonth) > ((surveyB.surveyYear * 100) + surveyB.surveyMonth)) {
            sorted = 1;
          } else {
            sorted = -1;
          }
        } else if ((a[1] as surveysSubProps).surveyYear > (b[1] as surveysSubProps).surveyYear) {
          sorted = 1;
        } else {
          sorted = -1;
        }
        return sorted;
      });
      const x: (number|string)[] = data.map((i) => {
        const survey = i[1] as surveysSubProps;
        let filterDate: string|number = survey.surveyYear;
        if (this.isMonth) {
          filterDate = dayjs().set('year', survey.surveyYear).set('month', survey.surveyMonth - 1).format('MMM/YYYY');
        }
        return filterDate;
      });
      const y: number[] = data.map((i) => i[0] as number);
      const text = data.map((i) => {
        const l = i[1] as surveysSubProps;
        let loc = this.parseLocationName(l);
        if (typeof (loc) !== 'string') {
          loc = 'N/A';
        }
        return loc;
      });
      const customdata = data.map((i) => {
        const propLow = i[2];
        const propUp = i[3];
        return `${propLow.toFixed(1)} - ${propUp.toFixed(1)}`;
      });
      const hovertemplate = generateHoverTemplates(x, y, text, customdata, '#4387cc');
      const traces:Data = {
        x,
        y,
        text,
        customdata,
        textinfo: 'none',
        hovertemplate,
        hoverlabel: {
          bgcolor: '#363534',
          bordercolor: '#363534',
          font: {
            color: '#FFFFFF',
            family: 'DINNextLTPro',
            size: 12.5,
          },
          align: 'left',
        },
        mode: 'markers',
        type: 'scatter',
        marker: {
          color: '#4387cc',
          size: 8,
          opacity: 0.5,
        },
        textfont: {
          family: 'DINNextLTPro-Regular',
        },
        showlegend: false,
      };
      let criticalTraces:Data = {};
      if (this.type[0] === 'GAM') {
        criticalTraces = {
          x: [x[0]],
          y: [15],
          mode: 'lines',
          showlegend: true,
          line: {
            color: '#f72e57',
            dash: 'dash',
          },
          hoverinfo: 'skip',
          name: 'Critical',
        };
      }
      return [traces, criticalTraces];
    },
    parseLocationName(marker:surveysSubProps) {
      return marker.geographicAreaName;
    },
  },
  watch: {
    data(newProps, oldProps) {
      if (newProps !== oldProps) {
        this.gam();
      }
    },
    sex(newProps, oldProps) {
      if (newProps !== oldProps) {
        this.gam();
      }
    },
    type(newProps, oldProps) {
      if (newProps !== oldProps) {
        this.gam();
      }
    },
    filter(newProps, oldProps) {
      if (newProps !== oldProps) {
        this.gam();
      }
    },
  },
});
