
import { defineComponent } from 'vue';
import { Select } from 'ant-design-vue';
import { CaretDownFilled } from '@ant-design/icons-vue';
import AnthropometryProfAndPrev from './AnthropometryProfAndPrev/index.vue';
import AnthropometryMap from './AnthropometryMap/index.vue';
import AnthropometryResults from './AnthropometryResults/index.vue';

const { Option } = Select;

export default defineComponent({
  name: 'Anthropometry',
  components: {
    AnthropometryProfAndPrev,
    AnthropometryMap,
    AnthropometryResults,
    Option,
    Select,
    CaretDownFilled,
  },
  data() {
    return {
      viewBy: 'GAM',
      loc: 'All',
    };
  },
  computed: {
    getLoading(): string {
      return this.$store.getters['surveysFilter/getLoading'];
    },
  },
  methods: {
    thresholds(num: number): number {
      const { viewBy } = this;
      let category = 0;
      if (viewBy === 'GAM') {
        if (num >= 2.5 && num < 5) category = 1;
        if (num >= 5 && num < 10) category = 2;
        if (num >= 10 && num < 15) category = 3;
        if (num >= 15) category = 4;
      } else if (viewBy === 'Stunting') {
        if (num >= 2.5 && num < 10) category = 1;
        if (num >= 10 && num < 20) category = 2;
        if (num >= 20 && num < 30) category = 3;
        if (num >= 30) category = 4;
      } else if (viewBy === 'Overweight') {
        if (num >= 2.5 && num < 5) category = 1;
        if (num >= 5 && num < 10) category = 2;
        if (num >= 10 && num < 15) category = 3;
        if (num >= 15) category = 4;
      }
      return category;
    },
  },
  watch: {
    getLoading(newProps, oldProps) {
      if (newProps === 'done' && oldProps === 'loading') {
        const getLoc = this.$store.getters['surveysFilter/getLocation'];
        if (getLoc) {
          this.loc = getLoc;
        } else {
          this.loc = 'All';
        }
      }
    },
  },
});
