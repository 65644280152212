
import { defineComponent } from 'vue';
import { surveysSubProps } from '@/utils/request';
import Plotly, { Data } from 'plotly.js-finance-dist-min';

export default defineComponent({
  name: 'AnthropometryPrev',
  props: {
    viewBy: {
      type: String,
    },
    thresholds: {
      type: Function,
    },
  },
  mounted() {
    this.graph();
  },
  computed: {
    data() {
      const surveys = this.$store.getters['surveysFilter/getData'];
      const arr:number[] = [];
      surveys.forEach((survey: surveysSubProps) => {
        let m;
        if (this.viewBy === 'GAM') m = survey?.keystats?.gam?.whz?.all?.prop;
        if (this.viewBy === 'Stunting') m = survey?.keystats?.stunting?.global?.all?.prop;
        if (this.viewBy === 'Overweight') m = survey?.keystats?.overweight?.global?.all?.prop;
        if (typeof m !== 'undefined' && survey?.surveyYear && survey?.surveyMonth) {
          m *= 100;
          arr.push(m);
        }
      });
      return arr;
    },
  },
  methods: {
    graph() {
      let text = '';
      if (this.$props.viewBy === 'GAM') text = 'GAM shown as Weight-for-Height';
      const { width, height } = (this.$refs.AthroPrev as Element).getBoundingClientRect();
      const layout = {
        height: 170,
        width,
        plot_bgcolor: '#f5f5f5',
        title: {
          text,
          font: {
            color: '#8e908f',
            family: 'DINNextLTPro-Regular',
            size: 14,
          },
          xref: 'paper',
          x: 0,
        },
        yaxis: {
          type: 'category',
          showticklabels: false,
          linecolor: '#cdcdcd',
        },
        xaxis: {
          linecolor: '#cdcdcd',
          range: [0, 105],
          zeroline: false,
          tickformat: '.1f',
          tickfont: {
            color: 'rgba(54, 53, 52, 0.74)',
            family: 'DINNextLTPro-Regular',
          },
        },
        margin: {
          t: 50,
          l: 25,
          r: 0,
        },
      };
      const data = this.renderPrev();
      // @ts-expect-error enum error
      Plotly.newPlot('anthro-prev', data, layout);
    },
    renderPrev(): Data[] {
      const x = this.data;
      const c = ['#2caf80', '#8cd07f', '#f3c912', '#e98300', '#e41919'];
      const colors = x.map((i:number) => (c[(this.$props.thresholds as (arg0:number) => number)(i)]));
      const traces:Data = {
        y: new Array(x.length).fill(0),
        x,
        mode: 'markers',
        marker: {
          size: 8,
          color: colors,
        },
      };
      return [traces];
    },
  },
  watch: {
    viewBy(newProps, oldProps) {
      if (newProps !== oldProps) {
        this.graph();
      }
    },
    data() {
      this.graph();
    },
  },
});
