import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GAM = _resolveComponent("GAM")!
  const _component_Stunting = _resolveComponent("Stunting")!
  const _component_Underweight = _resolveComponent("Underweight")!
  const _component_Overweight = _resolveComponent("Overweight")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_GAM, {
      data: _ctx.getData,
      isMonth: _ctx.getDateDiff
    }, null, 8, ["data", "isMonth"]),
    _createVNode(_component_Stunting, {
      data: _ctx.getData,
      isMonth: _ctx.getDateDiff
    }, null, 8, ["data", "isMonth"]),
    _createVNode(_component_Underweight, {
      data: _ctx.getData,
      isMonth: _ctx.getDateDiff
    }, null, 8, ["data", "isMonth"]),
    _createVNode(_component_Overweight, {
      data: _ctx.getData,
      isMonth: _ctx.getDateDiff
    }, null, 8, ["data", "isMonth"])
  ], 64))
}